<script>
import api from '@/command/api'
import apiTool from '@/command/apiTool'
import DrawerForm from '../../../components/DrawerForm'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
export default {
  name: 'idle',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/farmLdleTransInfo/page',
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '发布者',
          type: 'input',
          key: 'createUserName'
        },
        {
          label: '',
          type: 'text'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'description',
          title: '描述',
          align: 'left',
          sorter: (a, b) => a.description.length - b.description.length
        },
        {
          dataIndex: 'typeDesc',
          title: '闲置分类',
          align: 'left',
          customRender: function(text, records) {
            return <div>{records.typeDesc + '/' + records.subTypeDesc}</div>
          },
          onExport: (text, records) => {
            return <div>{records.typeDesc + '/' + records.subTypeDesc}</div>
          }
        },
        {
          dataIndex: 'publishingType',
          title: '来源平台',
          align: 'left',
          customRender: function(text, records) {
            return <div>{text == '0' ? '手掌农场 ' : '未来乡村'}</div>
          },
          filters: [
            {
              text: '未来乡村',
              value: '1'
            },
            {
              text: '手掌农场',
              value: '0'
            }
          ],
          filterMultiple: false,
          onExport: (text, records) => {
            return ['手掌农场', '未来乡村'][text]
          }
        },
        {
          dataIndex: 'appName',
          title: '应用',
          align: 'left'
        },
        {
          dataIndex: 'createUserName',
          title: '发布者',
          align: 'left'
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认删除？',
                onClick: () => {
                  api.command.delPostArr.call(this, {
                    url: '/farmLdleTransInfo/delete',
                    params: {
                      ids: [records.id]
                    }
                  })
                }
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return [
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: data => {
                let ids = data.map(e => e.id)
                return api.command.delPostArr.call(this, {
                  url: '/farmLdleTransInfo/delete',
                  params: {
                    ids: ids
                  }
                })
              }
            }
          ]
        }
      ]
    },
    getDrawerForm(records, formData) {
      const form = [
        {
          title: '基础信息',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '闲置分类',
                  type: 'text',
                  value: records.typeDesc + '/' + records.subTypeDesc,
                  width: '100%'
                },
                {
                  name: '来源平台',
                  type: 'text',
                  value: ['手掌农场', '未来乡村'][records.publishingType],
                  width: '100%'
                },
                {
                  name: '发布者',
                  type: 'text',
                  value: records.createUserName
                }
              ]
            }
          ]
        },
        {
          title: '详情',
          form: [
            {
              type: 'text',
              name: '价 格',
              value: records.discussionFalg == '0' ? records.price : '面议'
            },
            {
              name: '地 点',
              type: 'text',
              value: records.provinceName + records.cityName + records.areaName + records.address
            },
            {
              name: '录 音',
              type: 'text',
              value: records.voiceUrl
            },
            {
              type: 'text',
              value: records.description
            },
            {
              type: 'imageGroup',
              value: records.imgUrl
            }
          ]
        }
      ]
      return form
    },
    onRowSelect(records) {
      apiTool.showDrawer({
        title: '闲置信息',
        width: '600px',
        view: DrawerForm,
        viewProps: {
          data: formData => this.getDrawerForm(records, formData),
          form: records,
          formProps: {
            layout: 'horizontal',
            labelAlign: 'left',
            cols: 24,
            labelCol: { span: 2 },
            wrapperCol: { span: 21 }
          }
        }
      })
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
        onSelectRow={this.onRowSelect}
      />
    )
  }
}
</script>
